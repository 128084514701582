.scrolling-logos-container {
  display: flex;
  animation: scrollLogos 60s linear infinite;
  white-space: nowrap;
}

.scrolling-logos-container.right {
  display: flex;
  animation: scrollLogos 60s linear infinite reverse;
  white-space: nowrap;
}

@keyframes scrollLogos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
