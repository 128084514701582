.ecosystem-diagram {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity:0.5;
}

.ecosystem-diagram__logo-container {
  position: relative;
  width: 75%; /* Added to adjust the width of the logo container */
  max-width: 100%; /* Added to enforce a maximum width */
  overflow: hidden; /* Added to hide any overflowing content */
}

.ecosystem-diagram__logo {
  width: 150px;
  height: auto;
  margin: 10px;
}

.ecosystem-diagram__line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
}

.ecosystem-diagram__line line {
  stroke: #000;
  stroke-width: 1px;
}

@media (max-width: 768px) {
  .ecosystem-diagram__logo-container {
    width: 100%;
  }
  
  .ecosystem-diagram__logo-container iframe {
    width: 100%;
    height: 300px; /* Adjust the height as needed */
  }
}
