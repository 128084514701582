.diagram-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Set a minimum height to occupy the viewport height */
}

@media (min-width: 768px) {
  .diagram-container {
    height: auto; /* Reset the height for larger screens */
  }
}
